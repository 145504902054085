






























































































































































































































































































































































.editor-container{
  .editor{
    &-overflow{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 26;
      display: none;
    }
    &-settings{
      width: 100%;
      max-width: 300px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
      padding-right: 50px;
      position: fixed;
      top: 30px;
      left: 30px;
      z-index: 28;
      display: none;
      input{
        width: 100%;
        height: 32px;
        padding: 0 12px 0 12px;
        background-color: #fff;
        border-radius: 4px;
        outline: none;
        font-size: 14px;
        color: #161616;
        &::placeholder{
          color: #AAAAAA;
        }
      }
      &-wrapper{
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        line-height: 0;
        .btn{
          margin-right: 8px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      &-btn{
        display: inline-block;
        width: 16px;
        line-height: 0;
        .fill-grey{
          fill: #767676;
          transition: all .24s ease-out;
        }
        .icon{
          display: inline-block;
          line-height: 0;
        }
      }
    }
    &-wrapper{
      &.is-settings{
        .editor-overflow,
        .editor-settings{
          display: block;
        }
      }
    }
  }
  .editor-wrapper{
    display: block;
    margin-bottom: 16px;
    .ProseMirror {
      min-height: 50px;
      outline: none;
      color: #161616;
      border-radius: 6px;
      font-size: 14px;
      > * + * {
        margin-top: 8px;
      }
      a:not(.btn-outlined){
        color: #0F62FE;
        text-decoration: none;
        font-weight: 500;
      }
      a[target='btn-outlined'] {
        padding: 6px 12px;
        border-radius: 6px;
        background-color: rgba(1, 1, 1, 0);
        border: 1px solid #E6E6E6;
        color: #5E4BF1;
        transition: all 0.34s ease-out;
        font-weight: 500;
        margin-right: 8px;
        display: inline;
      }
    }
    .ProseMirror p.is-editor-empty:first-child::before {
      content: 'Description';
      float: left;
      color: #AAAAAA;
      pointer-events: none;
      height: 0;
    }
  }
}
.editor-settings-btn:hover{
  .fill-grey{
    fill: #5E4BF1;
  }
}
.image-upload{
  position: relative;
  padding-bottom: 36%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
  &-inner{
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      img{
        max-width: 100%;
      }
    }
  }
  .up-img{
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.drop-block{
  transition: all .34s ease-out;
  &.is-hover{
      background-color: rgba(0, 0, 0, 0.12);
  }
}
.nav-editor{
  display: flex;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  &-btn{
    padding: 6px 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    color: #5E4BF1;
    font-size: 14px;
    font-weight: 500;
    transition: all .24s ease-out;
    &:last-child{
      border-right: none;
    }
  }
}
.nav-editor-btn:hover{
  opacity: 0.8;
}
